<template>
	<div>
		<!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
		<div v-if="inboxItems.length === 0  && !loading">
			<Empty></Empty>
		</div>
		<div v-else>
			<loading v-if="loading"></loading>
			<!-- Email List -->
			<div class="inbox-app-list" v-else>
				<b-row class="mx-15">
					<b-col>
						<h6 class="section-label mb-1">
							{{ $t('assignment.followAssignmentsAndNotifications') }}
						</h6>
					</b-col>
				</b-row>
				<b-row class="mx-15 mb-1">
					<b-col>
						<div class="d-flex align-content-end justify-content-end w-50">
							<b-form-input :value="searchQuery" :placeholder="$t('search')" v-model="search"/>
						</div>
					</b-col>
				</b-row>

				<!-- App Action Bar -->
				<div class="app-action">
					<div class="action-left">
						<!--            <b-form-checkbox :checked="selectAllItemsCheckbox"-->
						<!--                             :indeterminate="isSelectAllItemsCheckboxIndeterminate"-->
						<!--                             @change="selectAllCheckboxUpdate">-->
						{{ $t('all') }}
						<!--            </b-form-checkbox>-->
					</div>
					<div v-show="selectedItems.length" class="align-items-center" :class="{'d-flex': selectedItems.length}">

						<!-- Update Mail Folder Dropdown -->
						<b-dropdown variant="link" no-caret toggle-class="p-0" right>
							<template #button-content>
								<feather-icon icon="FolderIcon" size="17" class="align-middle text-body"/>
							</template>

							<b-dropdown-item @click="moveSelectedMessagesToFolder('draft')">
								<feather-icon icon="Edit2Icon"/>
								<span class="align-middle ml-50">Draft</span>
							</b-dropdown-item>

							<b-dropdown-item @click="moveSelectedMessagesToFolder('spam')">
								<feather-icon icon="InfoIcon"/>
								<span class="align-middle ml-50">Spam</span>
							</b-dropdown-item>

							<b-dropdown-item v-show="$route.params.folder !== 'trash'" @click="moveSelectedMessagesToFolder('trash')">
								<feather-icon icon="TrashIcon"/>
								<span class="align-middle ml-50">Trash</span>
							</b-dropdown-item>
						</b-dropdown>

						<!-- Update Mail Folder Dropdown -->
						<b-dropdown variant="link" no-caret toggle-class="p-0" class="ml-1" right>
							<template #button-content>
								<feather-icon icon="TagIcon" size="17" class="align-middle text-body"/>
							</template>
							<b-dropdown-item @click="updateSelectedMessagesLabel('personal')">
								<span class="mr-50 bullet bullet-success bullet-sm"/>
								<span>Personal</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('company')">
								<span class="mr-50 bullet bullet-primary bullet-sm"/>
								<span>Company</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('important')">
								<span class="mr-50 bullet bullet-warning bullet-sm"/>
								<span>Important</span>
							</b-dropdown-item>
							<b-dropdown-item @click="updateSelectedMessagesLabel('private')">
								<span class="mr-50 bullet bullet-danger bullet-sm"/>
								<span>Private</span>
							</b-dropdown-item>
						</b-dropdown>

						<feather-icon icon="MailIcon" size="17" class="cursor-pointer ml-1" @click="markSelectedMessagesAsUnread"/>
						<feather-icon v-show="$route.params.folder !== 'trash'" icon="TrashIcon" size="17" class="cursor-pointer ml-1" @click="moveSelectedMessagesToFolder('trash')"/>
					</div>
				</div>

				<!-- Emails List -->
				<div class="inbox-user-list scroll-area">
					<ul class="inbox-media-list">
						<b-media v-for="item in filteredList" :key="item.id" tag="li" no-body :class="{ 'mail-read': item.read }">
							<b-media-aside class="media-left mr-50">
								<b-avatar class="avatar" size="40" variant="primary" :text="setInitials(item.sender)"/>
							</b-media-aside>
							<b-media-body>
								<div class="mail-details">
									<div class="mail-items">
										<h5 class="mb-25">{{ item.sender }}</h5>
										<!--                    <span v-if="item.messageType.name === 'WAITING_FOR_REVIEW_MSG' || item.messageType.name === 'ASSIGNED_TO_SECTION' || item.messageType.name === 'ADDED_TO_GROUP_MSG'"  class="text-truncate">{{ item.subject }}</span>-->
										<!--                    <span v-else-if="item.messageType.name === 'COMMENT_NOTIFICATION_MSG'" class="text-truncate">{{ item.summary }}</span>-->
										<span class="text-truncate">{{ item.subject }}</span>

										<template v-if="item.additionalData !== null">
											<br/>
											<b-badge v-if="item.messageType.name === 'SECTION_REJECTED_MSG'" class="text-truncate" variant="light-danger">
												{{ $t('assignment.rejectReason') }}
												{{ item.additionalData.sectionRejectNote }}
											</b-badge>
										</template>
									</div>

									<div class="mail-meta-item align-content-center">
										<b-badge variant="light-warning" class="mx-50">
											{{ item.messageType.displayText }}
										</b-badge>

										<b-badge variant="light-warning" class="mx-50" v-if="item.status.name === 'CLOSED'">
											{{ item.status.displayText }}
										</b-badge>

										<b-button v-if="item.documentStatus === 'WAITING_TO_APPROVE' && item.status.name === 'OPEN' && item.messageType.name === 'WAITING_FOR_REVIEW_MSG'" @click="approve(item.documentId, item.sectionId)" class="mx-50 py-25" variant="outline-primary" size="sm">
											{{ $t('accept') }}
										</b-button>

										<b-button v-if="item.documentStatus === 'WAITING_TO_APPROVE' && item.status.name === 'OPEN' && item.messageType.name === 'WAITING_FOR_REVIEW_MSG'" @click="reject(item.documentId, item.sectionId)" class="mx-50 py-25" variant="outline-primary" size="sm">
											{{ $t('reject') }}
										</b-button>

										<b-button v-if="redirectable(item.messageType.name)" variant="outline-primary" size="sm" :to="{ name: 'editor', params: { id: item.documentId }}" class="mx-50 py-25 mr-3">
											<feather-icon icon="ExternalLinkIcon" size="14"/>
										</b-button>

										<span v-for="label in item.labels" :key="label" class="mx-50 bullet bullet-sm" :class="`bullet-${resolveLabelColor(label)}`"/>
										<span class="mail-date">{{formatDateToMonthShort(item.createdAt, {hour: 'numeric', minute: 'numeric',}) }}</span>
									</div>
								</div>
							</b-media-body>
						</b-media>
					</ul>
				</div>
			</div>

			<!-- Email View/Detail -->
			<!--      <email-view-->
			<!--          :class="{'show': showEmailDetails}"-->
			<!--          :email-view-data="emailViewData"-->
			<!--          :opended-email-meta="opendedEmailMeta"-->
			<!--          @close-inbox-view="showEmailDetails = false"-->
			<!--          @move-inbox-to-folder="moveOpenEmailToFolder"-->
			<!--          @toggle-inbox-starred="toggleStarred(emailViewData)"-->
			<!--          @update-inbox-label="updateOpenEmailLabel"-->
			<!--          @mark-inbox-unread="markOpenEmailAsUnread"-->
			<!--          @change-opened-inbox="changeOpenedEmail"-->
			<!--      />-->

			<b-modal lazy size="lg" ref="section-reject-modal" hide-footer no-close-on-backdrop @hidden="hide" :title="$t('section.rejectSection')">
				<section-reject-modal :document-id="documentId" :section-id="sectionId" @hide="hide"/>
			</b-modal>
		</div>
	</div>
</template>

<script>
import {
	BAvatar,
	BBadge,
	BButton,
	BCol,
	BDropdown,
	BDropdownItem,
	BFormInput,
	BMedia,
	BMediaAside,
	BMediaBody,
	BRow
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useInbox from './../useInbox'
import Loading from "@/views/components/Loading/Loading";
import {mapState} from "vuex";
import Empty from "@/views/Assignment/Inbox/Empty";
import SectionRejectModal from "@/views/Documents/Editor/Sections/Approvers/SectionRejectModal"
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import StringUtil from "@/services/StringUtil";

export default {
	components: {
		Empty,
		BFormInput,
		BButton,
		BBadge,
		BRow, BCol,
		BDropdown,
		BDropdownItem,
		// BFormCheckbox,
		BMedia,
		BMediaAside,
		BMediaBody,
		BAvatar,
		Loading,
		// 3rd Party

		// App SFC
		// EmailView,

		SectionRejectModal
	},
	computed: {
		...mapState({
			loading: state => state.assignment.inboxLoading
		}),
		filteredList() {
			return this.inboxItems.filter(post => {
				return post.sender.toLowerCase().includes(this.search.toLowerCase()) || post.subject.toLowerCase().includes(this.search.toLowerCase());
			})
		}
	},
	data() {
		return {
			search: '',
			documentId: null,
			sectionId: null
		}
	},
	setup() {
		const { // UI
			perfectScrollbarSettings,
			// Emails & EmailsMeta
			reFetchData,
			fetchInbox,
			fetchSent,
			fetchClosed,
			inboxItems,
			inboxItemMeta,

			// Mail Selection
			selectAllItemsCheckbox,
			isSelectAllItemsCheckboxIndeterminate,
			selectedItems,
			toggleSelectedMail,
			selectAllCheckboxUpdate,

			// Mail Actions
			toggleStarred,
			moveSelectedMessagesToFolder,
			updateSelectedMessagesLabel,
			markSelectedMessagesAsUnread,

			// Email Details
			showEmailDetails,
			emailViewData,
			opendedEmailMeta,
			updateMessageViewData,
			moveOpenEmailToFolder,
			updateOpenEmailLabel,
			markOpenEmailAsUnread,
			changeOpenedEmail,

			// Search Query
			searchQuery,
			updateRouteQuery,

			// UI Filters
			filterTags,
			formatDateToMonthShort,

			// useEmail
			resolveLabelColor,

		} = useInbox()

		fetchInbox()

		return {
			// UI
			perfectScrollbarSettings,

			reFetchData,
			fetchInbox,
			fetchSent,
			fetchClosed,
			// Emails & EmailsMeta
			inboxItems,
			inboxItemMeta,

			// Mail Selection
			selectAllItemsCheckbox,
			isSelectAllItemsCheckboxIndeterminate,
			selectedItems,
			toggleSelectedMail,
			selectAllCheckboxUpdate,

			// Mail Actions
			toggleStarred,
			moveSelectedMessagesToFolder,
			updateSelectedMessagesLabel,
			markSelectedMessagesAsUnread,

			// Email Details
			showEmailDetails,
			emailViewData,
			opendedEmailMeta,
			updateMessageViewData,
			moveOpenEmailToFolder,
			updateOpenEmailLabel,
			markOpenEmailAsUnread,
			changeOpenedEmail,

			// Search Query
			searchQuery,
			// updateRouteQuery,

			// UI Filters
			filterTags,
			formatDateToMonthShort,

			// useEmail
			resolveLabelColor,


		}
	},
	methods: {
		approve(documentId, sectionId) {
			const payload = {
				documentId: documentId,
				sectionId: sectionId
			}

			this.$store.dispatch('reviews/approve', payload)
				.then(() => this.$toast({
					component: ToastificationContent,
					props: {
						title: i18n.t('messages.section.approved'),
						icon: 'CheckIcon',
						variant: 'success',
					},
				}))
				.then(() => {
					this.fetchInbox()
					this.reFetchData()
				})
		},
		reject(documentId, sectionId) {
			this.documentId = documentId
			this.sectionId = sectionId
			this.$refs["section-reject-modal"].show()
		},
		hide() {
			this.documentId = null
			this.sectionId = null

			this.$refs["section-reject-modal"].hide()
			this.fetchInbox()
			this.reFetchData()
		},
		redirectable(status) {
			return (status === 'WAITING_FOR_REVIEW_MSG'
				|| status === 'WAITING_TO_BE_SIGNED_MSG'
				|| status === 'COMMENT_NOTIFICATION_MSG'
				|| status === 'SECTION_CHANGED_MSG'
				|| status === 'SECTION_APPROVED_MSG'
				|| status === 'SECTION_REJECTED_MSG'
				|| status === 'RE_DEMANDED_REVIEW_MSG'
				|| status === 'DOCUMENT_SHARED_MSG')
		},
		setInitials(text) {
			return StringUtil.setInitials(text)
		}
	},
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/inbox-page.scss";
</style>
