import {useRouter} from "@core/utils/utils";
import {computed, ref, watch} from "@vue/composition-api";
import store from "@/store";
import {filterTags, formatDateToMonthShort} from "@core/utils/filter";

export default function useInbox() {

  const {route, router} = useRouter()

  // Route Params
  const routeParams = computed(() => route.value.params)
  watch(routeParams, () => {
    // eslint-disable-next-line no-use-before-define
    // fetchInbox()
    reFetchData()
  })

  // Emails & EmailsMeta
  const inboxItems = ref([])
  const inboxItemMeta = ref({})

  const perfectScrollbarSettings = {
    maxScrollbarLength: 150,
    wheelPropagation: false,
  }

  // Search Query
  const routeQuery = computed(() => route.value.query.q)
  let searchQuery = ref("")
  // watch(routeQuery, val => {
  //   searchQuery.value = val
  // })
  // eslint-disable-next-line no-use-before-define
  // watch(searchQuery, (val) => {
  //   debugger
  //   console.log(" val ")
  //   console.log(val)
  //   console.log(" val ")
  //   // fetchInbox()
  // })
  const updateRouteQuery = val => {
    const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

    if (val) currentRouteQuery.q = val.target.value
    else delete currentRouteQuery.q

    router.replace({name: route.name, query: currentRouteQuery})
  }

  const updateInboxSearchQuery = (val) => {
    searchQuery.value = val.target.value
    fetchInbox()
  }

  const updateSentSearchQuery = (val) => {
    searchQuery.value = val.target.value
    fetchSent()
  }

  const updateClosedSearchQuery = (val) => {
    searchQuery.value = val.target.value
    fetchClosed()
  }

  const fetchInbox = () => {
    store.dispatch('assignment/fetchInbox', {
      q: searchQuery.value,
    })
        .then(response => {
          inboxItems.value = response.assignments
          // inboxItemMeta.value = response.data.emailsMeta
        })
  }

  const fetchSent = () => {
    store.dispatch('assignment/fetchSent', {
      q: searchQuery.value,
    })
        .then(response => {
          inboxItems.value = response.assignments
          // inboxItemMeta.value = response.data.emailsMeta
        })
  }

  const fetchClosed = () => {
    store.dispatch('assignment/fetchClosed', {
      q: searchQuery.value,
    })
        .then(response => {
          inboxItems.value = response.assignments
          // inboxItemMeta.value = response.data.emailsMeta
        })
  }

  const reFetchData = () => {
    inboxItems.value.refresh()
  }

  // ------------------------------------------------
  // Mail Selection
  // ------------------------------------------------
  const selectedItems = ref([])
  const toggleSelectedMail = mailId => {
    const mailIndex = selectedItems.value.indexOf(mailId)

    if (mailIndex === -1) selectedItems.value.push(mailId)
    else selectedItems.value.splice(mailIndex, 1)
  }
  const selectAllItemsCheckbox = computed(() => inboxItems.value.length && (inboxItems.value.length === selectedItems.value.length))
  const isSelectAllItemsCheckboxIndeterminate = computed(() => Boolean(selectedItems.value.length) && inboxItems.value.length !== selectedItems.value.length)
  const selectAllCheckboxUpdate = val => {
    selectedItems.value = val ? inboxItems.value.map(mail => mail.id) : []
  }
  // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

  // ------------------------------------------------
  // Mail Actions
  // ------------------------------------------------
  const toggleStarred = email => {
    store.dispatch('app-inbox/updateEmail', {
      emailIds: [email.id],
      dataToUpdate: {isStarred: !email.isStarred},
    }).then(() => {
      // eslint-disable-next-line no-param-reassign
      email.isStarred = !email.isStarred
    })
  }

  const moveSelectedMessagesToFolder = folder => {
    store.dispatch('app-inbox/updateEmail', {
      emailIds: selectedItems.value,
      dataToUpdate: {folder},
    })
        .then(() => {
          fetchInbox()
        })
        .finally(() => {
          selectedItems.value = []
        })
  }

  const updateSelectedMessagesLabel = label => {
    store.dispatch('app-inbox/updateEmailLabels', {
      emailIds: selectedItems.value,
      label,
    })
        .then(() => {
          fetchInbox()
        })
        .finally(() => {
          selectedItems.value = []
        })
  }

  const markSelectedMessagesAsUnread = () => {
    store.dispatch('app-inbox/updateEmail', {
      emailIds: selectedItems.value,
      dataToUpdate: {isRead: false},
    })
        .then(() => {
          fetchInbox()
        })
        .finally(() => {
          selectedItems.value = []
        })
  }

  // ------------------------------------------------
  // Email Details
  // ------------------------------------------------
  const showEmailDetails = ref(false)
  const emailViewData = ref({})
  const opendedEmailMeta = computed(() => {
    const openedEmailIndex = inboxItems.value.findIndex(e => e.id === emailViewData.value.id)
    return {
      hasNextEmail: Boolean(inboxItems.value[openedEmailIndex + 1]),
      hasPreviousEmail: Boolean(inboxItems.value[openedEmailIndex - 1]),
    }
  })
  const updateMessageViewData = email => {
    // Mark email is read
    store.dispatch('app-inbox/updateEmail', {
      emailIds: [email.id],
      dataToUpdate: {isRead: true},
    })
        .then(() => {
          // If opened email is unread then decrease badge count for email meta based on email folder
          if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
            inboxItemMeta.value[email.folder] -= 1
          }

          // eslint-disable-next-line no-param-reassign
          email.isRead = true
        })
        .finally(() => {
          emailViewData.value = email
          showEmailDetails.value = true
        })
  }
  const moveOpenEmailToFolder = folder => {
    selectedItems.value = [emailViewData.value.id]
    moveSelectedMessagesToFolder(folder)
    selectedItems.value = []
    showEmailDetails.value = false
  }
  const updateOpenEmailLabel = label => {
    selectedItems.value = [emailViewData.value.id]
    updateSelectedMessagesLabel(label)

    // Update label in opened email
    const labelIndex = emailViewData.value.labels.indexOf(label)
    if (labelIndex === -1) emailViewData.value.labels.push(label)
    else emailViewData.value.labels.splice(labelIndex, 1)

    selectedItems.value = []
  }

  const markOpenEmailAsUnread = () => {
    selectedItems.value = [emailViewData.value.id]
    markSelectedMessagesAsUnread()

    selectedItems.value = []
    showEmailDetails.value = false
  }

  const changeOpenedEmail = dir => {
    const openedEmailIndex = inboxItems.value.findIndex(e => e.id === emailViewData.value.id)
    const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
    emailViewData.value = inboxItems.value[newEmailIndex]
  }

  // * If someone clicks on filter while viewing detail => Close the email detail view
  watch(routeParams, () => {
    showEmailDetails.value = false
  })

  // * Watcher to reset selectedEmails
  // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
  // eslint-disable-next-line no-use-before-define
  watch([emailViewData, routeParams], () => {
    selectedItems.value = []
  })

  const resolveLabelColor = label => {
    if (label === 'personal') return 'success'
    if (label === 'company') return 'primary'
    if (label === 'important') return 'warning'
    if (label === 'private') return 'danger'
    return 'secondary'
  }

  return {
    // UI
    perfectScrollbarSettings,

    reFetchData,

    // Emails & EmailsMeta
    fetchInbox,
    fetchSent,
    fetchClosed,
    inboxItems,
    inboxItemMeta,

    // Mail Selection
    selectAllItemsCheckbox,
    isSelectAllItemsCheckboxIndeterminate,
    selectedItems,
    toggleSelectedMail,
    selectAllCheckboxUpdate,

    // Mail Actions
    toggleStarred,
    moveSelectedMessagesToFolder,
    updateSelectedMessagesLabel,
    markSelectedMessagesAsUnread,

    // Email Details
    showEmailDetails,
    emailViewData,
    opendedEmailMeta,
    updateMessageViewData,
    moveOpenEmailToFolder,
    updateOpenEmailLabel,
    markOpenEmailAsUnread,
    changeOpenedEmail,

    // Search Query
    searchQuery,
    updateInboxSearchQuery,
    updateSentSearchQuery,
    updateClosedSearchQuery,
    updateRouteQuery,

    // UI Filters
    filterTags,
    formatDateToMonthShort,

    // useEmail
    resolveLabelColor,
  }
}
