const StringUtil = {
  reduceText(text, size) {
    if (text != null && (text.length > size)) {
      return text.substring(0, size) + " ..."
    } else {
      return text
    }
  },

  setInitials(text) {
    let result = ''

    const parts = text.split(' ');
    for (const part of parts) {
      if  (part !== '') {
        result += part.split('')[0].toUpperCase()
      }
    }

    return result
  }
}

export default StringUtil
