<template>
  <div>
    <b-row class="px-1">
      <b-form-textarea v-model="note"
                       rows="4"
                       :state="note.length <= 200"
                       :placeholder="$t('section.rejectCause')"/>
    </b-row>
    <br/>
    <b-row align-h="end" class="px-1">
      <b-button @click="rejectSection"
                :disabled="note === '' || note.length >= 200"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary">
        {{ $t('buttons.save') }}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {BButton, BFormTextarea, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
import {PEventBus} from "@/services/PEventBus";

export default {
  name: "SectionRejectModal",
  directives: {
    Ripple
  },
  components: {
    BRow,
    BFormTextarea,
    BButton
  },
  props: {
    documentId: {
      type: String
    },
    sectionId: {
      type: String
    }
  },
  data() {
    return {
      note: ''
    }
  },
  methods: {
    rejectSection() {
      const payload = {
        documentId: this.documentId,
        sectionId: this.sectionId,
        request: {
          note: this.note
        }
      }

      this.$store.dispatch('reviews/reject', payload)
          .then(() => PEventBus.$emit("load-sections"))
          .then(() => this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('messages.section.rejected'),
              icon: 'CheckIcon',
              variant: 'warning',
            },
          }, {position: 'top-center'}))
      this.$emit("hide")
    }
  }
}
</script>

<style scoped>

</style>